import React from 'react'
import './navbar.css'
const Navbar = () => {
  return (
    <div className="header">
      <div className="navbar">
            <a href="#" className="header__logo">Jung Hoon Seo</a>
            {/* <div className="nav__container">
              <li><a href="#">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#experience">Experience</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#contact">Contact</a></li>    
            </div> */}
      </div>
    </div>
  )
}


export default Navbar