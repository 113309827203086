import Contact from 'components/contact/Contact'
import Header from 'components/header/Header'
import Nav from 'components/nav/Nav'
import Testimonials from 'components/testimonials/Testimonials'
import Footer from 'components/footer/Footer'
import Portfolio from 'components/portfolio/Portfolio'
import React from 'react'
import About from 'components/about/About'
import Experience from 'components/experience/Experience'
import Services from 'components/services/Services'
import Navbar from 'components/Navbar/Navbar'

const App = () => {
    return (
        <>
            <Navbar/>
            <Header />
            <Nav />
            <About />
            <Experience />
            {/* <Services /> */}
            <Portfolio />
            {/* <Testimonials /> */}
            <Contact />
            <Footer />
        </>
    )
}

export default App