import React from 'react'
import './about.css'
import ME from '../../assets/me-about2.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import { IoIosSchool } from "react-icons/io";
import { FaRegHandshake } from "react-icons/fa";
import { GiPublicSpeaker } from "react-icons/gi";
const About = () => {
  return (
    <section id ='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image"/>
          </div>
        </div>

        <div className="about__content">
        
          <div className="about__cards">
          <div className="about__cards__outer">
            <article className="about__card">
              <GiPublicSpeaker className='about__icon'/>
              <h5>Leadership</h5>
              <small>2+ years leadership experience</small>
            </article>
            </div>
            <article className="about__card">
              <FiUsers className='about__icon'/>
              <h5>Teamwork</h5>
              <small>2+ Internship Experiences</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Motivation</h5>
              <small>20+ Projects Completed</small>
            </article>   

          </div>
          <h4>Howdy!</h4>
          <p>            
              my name is Jung. I am a student at Texas A&M University studying computer science.
          </p>         
          <a href="#contact" className='btn btn-primary'>Let's talk</a>
        </div>
      </div>
    </section>
  )
}

export default About