import React from 'react'
import './header.css'
import CTA from './CTA' 
import ME from '../../assets/me2.png'
import HeaderSocials from './HeaderSocials'
const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h4>Howdy, I'm</h4>
        <h1>Jung Hoon Seo</h1>
        <h5 className="text-light">Graduate Student at Texas A&M University</h5>
        
        <HeaderSocials />
        <div className="me">
          <img src={ME} alt="me"/>
        </div>        
        <CTA />
        <a href="#contact" className="scroll__down"> Scroll Down</a>
      </div>        
    </header>
  )
}

export default Header