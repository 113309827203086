import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import { IoIosSchool } from "react-icons/io";
const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.tamu.edu/" target="_blank"><IoIosSchool/></a>
        <a href="https://www.linkedin.com/in/jung-hoon-seo-5ab813178/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/jseo0917?tab=repositories" target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials