import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'
import { IoRefreshCircleOutline } from "react-icons/io5";

const data = [
  {
    id: 4,
    image: IMG4,
    title: 'Learning-augmented skip list',
    summary: 'This project aims to support the agents in a customer chat service by providing automated summarization in real time.',
    finished: false,
    github: 'https://github.com/jseo0917/NLP_GROUP_PROJECT',
    demo: "https://drive.google.com/file/d/1hySrzQ1ghk4TP_zok_NV4jzgd8_eGHV0/view?usp=sharing"
  },
  {
    id: 5,
    image: IMG5,
    title: 'Fabrication Studio',
    summary: 'This project aims to support the agents in a customer chat service by providing automated summarization in real time.',
    finished: true,
    github: 'https://jseo0917.github.io/index.html',
    demo: "https://jseo0917.github.io/index.html"
  },
  {
    id: 1,
    image: IMG1,
    title: 'Dialog Summarization',
    summary: 'This project aims to support the agents in a customer chat service by providing automated summarization in real time.',
    finished: true,
    github: 'https://github.com/jseo0917/NLP_GROUP_PROJECT',
    demo: "https://drive.google.com/file/d/1hySrzQ1ghk4TP_zok_NV4jzgd8_eGHV0/view?usp=sharing"
  },
  {
    id: 2,
    image: IMG2,
    title: 'Credit Card Fraud Detection with an ensemble learner',
    summary: 'Developed an ensemble learner to cope with the side effects of under-sampling in attempt to improve the precision and recall of our classifier',
    finished: true,
    github: 'https://github.com/jseo0917/Overcoming-High-Accuracy-coupled-with-Low-Precision-and-Recall-in-Credit-Card-Fraud-Detection',
    demo: "https://drive.google.com/file/d/1m4nnGqUdIsiEbhOaGto0z3jsUV1ATTIq/view?usp=sharing"
  },
  {
    id: 3,
    image: IMG3,
    title: 'Statistical Analysis of Continuous Glucose Monitoring Data',
    summary: '',
    finished: true,
    github: 'https://github.com/moshimor91/iglu/blob/master/man/episode_calculation.Rd',
    demo: "https://drive.google.com/file/d/1eF9lHG3l8C9hT7jiFCjGHZte47GgonYr/view?usp=sharing"
  }
  
  // {
  //   id: 4,
  //   image: IMG4,
  //   title: 'Crypto Currecy DashBoard & Financial Visualization',
  //   summary: '',
  //   github: 'https://github.com',
  //   demo: "https://dribbble.com/Alien_pixels"
  // },
  // {
  //   id: 5,
  //   image: IMG5,
  //   title: 'Crypto Currecy DashBoard & Financial Visualization',
  //   summary: '',
  //   github: 'https://github.com',
  //   demo: "https://dribbble.com/Alien_pixels"
  // },
  // {
  //   id: 6,
  //   image: IMG6,
  //   title: 'Crypto Currecy DashBoard & Financial Visualization',
  //   summary: '',
  //   github: 'https://github.com',
  //   demo: "https://dribbble.com/Alien_pixels"
  // },
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

        {
          data.map(({id, image, title, summary, finished, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title}/>                  
                </div>
                  <h4>{title}  {finished? '': '(Coming Soon)!' }</h4>           
                                           
                  <div className="portfolio__item-cta">
                    <a href={github} className='btn'target='_blank'>GitHub</a>
                    <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>          
                  </div>            
              </article>
            )
            })
          }
   
      </div>
    </section>
  )
}

export default Portfolio