import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Skills</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Programming Languages</h3>
          <div className="experience__content">
          <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>C++</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>R</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>C#</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>   
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Fluent</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>TypeScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>            
          </div>
        </div>

        <div className="experience__backend">
          <h3>Technical Skills</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>  
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>Node JS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>SPRING</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>Spark</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>REST API</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className = "experience__details-icon" />
              <div>
                <h4>AWS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience